import { styled } from '@mui/system'
import { StaticImage } from 'gatsby-plugin-image'
import React from 'react'

const Movie: React.FC = () => {
    return (
        <Wrapper>
            <StaticImage
                src="../../images/common/movie.jpg"
                alt=""
                width={400}
            />
            <iframe
                allowFullScreen={true}
                frameBorder="0"
                width="100%"
                height="300px"
                src="https://www.youtube.com/embed/9Kezmp2l-nI"
                loading="lazy"
                title="品質とキャンペーン価格 | 3分でわかる miss platinum 動画"
            />
        </Wrapper>
    )
}

const Wrapper = styled('div')({
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    padding: '8px',
})

export default Movie
